import {token as Token, api as Api} from "@greywind/common";

declare const window: Window & typeof globalThis & {
    Echo: any
}

const state = {
    errors: null,
    user: {},
    isAuthenticated: !!Token.get(),
    peers: []
}

const getters = {
    peers( state ) {
        return state.peers;
    },

    currentUser( state ) {
        return state.user;
    },

    isAuthenticated( state ) {
        return state.isAuthenticated;
    },

    clients( state ) {
        return state.user.clients
    },

    activeClient( state ) {
        if ( !state.user.clients ) return {};

        return state.user.clients.filter( client => client.id === state.user.active_client_id )[ 0 ] || state.user.clients[ 0 ];
    },

    activeEnvironment( state ) {
        if ( !state.user.clients ) return {};

        const client = state.user.clients.filter( client => client.id === state.user.active_client_id )[ 0 ] || state.user.clients[ 0 ];
        if ( !client ) return {};

        return client.environments.filter( env => env.id === state.user.active_environment_id )[ 0 ] || client.environments[ 0 ];
    }
}

const actions = {
    setTray() {
        window.desktop.pushToTray(
            {
                label: 'Sign Out',
                event: 'click',
                eventName: 'sign-out',
                eventValue: true
            }
        );
    },
    setActiveClient( context, payload ) {
        return new Promise( ( resolve, reject ) => {
            if ( Token.get() ) {
                Api.fetch( 'post', 'auth/set-active-client', {
                    id: payload.cId,
                    env: payload.eId
                }, process.env.VUE_APP_API_URL )
                   .then( () => {
                       context.commit( 'updateActiveClient', payload );

                       resolve( true );
                   } )
                   .catch( () => {
                       reject( true );
                   } );
            } else reject( true );
        } );
    },
    logout( {commit, getters} ) {
        if ( window.Echo ) delete window.Echo.connector.options.auth.headers[ 'Authorization' ];

        commit( 'purge' );
    },
    login( context, payload ) {
        const {access_token} = payload;

        Token.store( access_token, 'token' );

        if ( window.Echo ) window.Echo.connector.options.auth.headers[ 'Authorization' ] = `Bearer ${Token.get()}`;
    },
    redirect() {
        return new Promise( ( resolve, reject ) => {
            if ( Token.get() ) {
                Api.fetch( 'post', 'auth/me', {}, process.env.VUE_APP_API_URL )
                   .then( () => {
                       resolve( true );
                   } )
                   .catch( () => {
                       reject( true );
                   } );
            } else reject( true );
        } );
    },
    verify( context ) {
        return new Promise( ( resolve, reject ) => {
            if ( Token.get() ) {
                Api.fetch( 'post', 'auth/me', {}, process.env.VUE_APP_API_URL )
                   .then( ( {data} ) => {
                       // Check user has the right to be here
                       // if (!ALLOWED_USER_TYPES.includes(data.data.type)) {
                       // 	router.push('/error/403');
                       // 	reject(true);
                       // }

                       context.commit( 'set', data.data );

                       resolve( true );
                   } )
                   .catch( () => {
                       // context.commit( 'purge' );

                       reject( true );
                   } );
            } else {
                // context.commit( 'purge' );

                reject( true );
            }
        } );
    },
    update( context, payload ) {
        const {id, email, password, name, meta, active} = payload;

        return Api.request( 'put', `users/${id}`, {
            email,
            password: password || null,
            name,
            meta,
            active
        }, process.env.VUE_APP_API_URL ).then( ( {data} ) => {
            context.commit( 'set', data.data );
            return data.data;
        } );
    },
    setPeers( {commit}, payload ) {
        commit( 'updatePeers', payload );
    },
    findPeer( {state}, payload ) {
        const index = state.peers.findIndex( p => p.id === payload );

        return index !== -1 ? state.peers[ index ] : null;
    }
}

const mutations = {
    updatePeers( state, payload ) {
        state.peers = payload;
    },
    updateActiveClient( state, payload ) {
        state.user.active_client_id = payload.cId;
        state.user.active_environment_id = payload.eId;
    },
    error( state, payload ) {
        state.errors = payload;
    },
    set( state, user ) {
        state.isAuthenticated = true;
        state.user = user;
        state.errors = {};
    },
    purge( state ) {
        state.isAuthenticated = false;
        state.user = {};
        state.errors = {};
        Token.remove();
    }
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}
