import { computed } from "vue";

export function load( store ): any {
    const backPath         = computed( () => store.getters[ "media/backPath" ] );
    const files            = computed( () => store.getters[ "media/allFiles" ] );
    const searchResults    = computed( () => store.state.media.searchResults );
    const allFilesSelected = computed( () => store.state.media.allFilesSelected );
    const selectedFiles    = computed( () => store.state.media.selectedFiles );
    const mode             = computed( () => store.state.media.mode );
    const activeFile       = computed( () => store.state.media.file );
    const filteredFiles    = computed( () => searchResults.value.length ? searchResults.value : files.value );

    const setPath             = ( payload: string ): any => store.dispatch( "media/setPath", payload );
    const setAllFilesSelected = ( payload: any ): any => store.dispatch( "media/setAllFilesSelected", payload );
    const setSelectedFiles    = ( payload: any ): any => store.dispatch( "media/setSelectedFiles", payload );
    const setFile             = ( payload: any ): any => store.dispatch( "media/setFile", payload );
    const setModal            = ( payload: any ): any => store.dispatch( "media/setModal", payload );

    const deleteFolder = ( path: string ): void => {
        setModal(
            {
                key:   'deleteFolder',
                value: {
                    open:  true,
                    value: path
                }
            }
        );
    }

    const deleteFile = ( file: any ): void => {
        setModal(
            {
                key:   'deleteFile',
                value: {
                    open:  true,
                    value: file
                }
            }
        );
    }

    const selectFile = ( file: any ): void => {
        if ( file.type === 'file' && [ 'field','BrandMediaLibrary' ].includes( mode.value ) ) {
            setFile( file );

            window.dispatchEvent( new CustomEvent( 'media:select', { detail: file } ) );
        }
    }

    const toggleAllFilesSelection = ( val: any ): void => {
        setAllFilesSelected( !!val );
        setSelectedFiles( val === true
                          ? files.value.filter( ( { type } ) => type !== 'folder' ).map( ( { id } ) => id )
                          : [] );
    }

    const toggleFileSelection = ( file: any, val: any ): void => {
        let allFilesSelected = true;

        const updatedSelection = [ ...selectedFiles.value ];
        if ( updatedSelection.indexOf( file.id ) === -1 && val === true ) updatedSelection.push( file.id );
        if ( updatedSelection.indexOf( file.id ) !== -1 && val === false ) updatedSelection.splice( updatedSelection.indexOf( file.id ), 1 );

        setSelectedFiles( updatedSelection );

        files.value.forEach( ( { id } ) => {
            if ( selectedFiles.value.indexOf( id ) === -1 ) allFilesSelected = false;
        } );

        setAllFilesSelected( allFilesSelected );
    }

    return {
        backPath,
        searchResults,
        allFilesSelected,
        selectedFiles,
        mode,
        activeFile,
        filteredFiles,
        setPath,
        setAllFilesSelected,
        setSelectedFiles,
        setFile,
        setModal,
        deleteFolder,
        deleteFile,
        selectFile,
        toggleAllFilesSelection,
        toggleFileSelection
    };
}
