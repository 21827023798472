import { api as Api, token as Token }               from "@greywind/common";

const state = {
    contents: 'Dashboard',
    back:     {},
    action:   {}
}

const getters = {
    contents( state ) {
        return state.contents;
    },
    back( state ) {
        return state.back
    },
    action( state ) {
        return state.action
    }
}

const actions = {
    setContents( { commit }, payload ) {
        commit( 'updateContents', payload );
    },
    setBack( { commit }, payload ) {
        commit( 'updateBack', payload );
    },
    setAction( { commit }, payload ) {
        commit( 'updateAction', payload );
    }
}

const mutations = {
    updateContents( state, payload ) {
        state.contents = payload;
    },
    updateBack( state, payload ) {
        state.back = payload;
    },
    updateAction( state, payload ) {
        state.action = payload;
    }
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}
