// @ts-ignore-start
import { FormSelect }             from "@ui/components/form-select";
import { FieldHtml }              from "@ui/components/field-html";
import { FieldRepeatable }        from "@ui/components/field-repeatable";
import { MediaField }             from "@ui/components/media";
import { MediaFilesPreviewPdf }   from "@ui/components/media/files/preview";
import { MediaFilesPreviewImage } from "@ui/components/media/files/preview";
import { MediaFilesPreviewNone }  from "@ui/components/media/files/preview";
// @ts-ignore-end

import {
    AppDateTimePicker,
    AppFieldBoolean,
    AppFieldColour,
    AppFieldDate,
    AppFieldDateTime,
    AppFieldDefault,
    AppFieldHidden,
    AppFieldRadio,
    AppFieldRange, AppFieldRichText,
    AppFieldSelect,
    AppFieldText,
    AppFieldTextarea,
    AppIcon,
    AppDateFilter,
    AppNumberFilter,
    AppOptionsFilter,
    AppTextFilter
} from "@greywind/ui";

export default {
    'ImagePreview':      MediaFilesPreviewImage,
    'PdfPreview':        MediaFilesPreviewPdf,
    'NoPreview':         MediaFilesPreviewNone,
    'html-field':        FieldHtml,
    'repeatable-field':  FieldRepeatable,
    'form-select-field': FormSelect,
    'media-field':       MediaField,
    'x-icon':             AppIcon,
    'app-date-filter':    AppDateFilter,
    'app-number-filter':  AppNumberFilter,
    'app-options-filter': AppOptionsFilter,
    'app-text-filter':    AppTextFilter,
    'date-time-picker':   AppDateTimePicker,
    'boolean-field':      AppFieldBoolean,
    'colour-field':       AppFieldColour,
    'date-field':         AppFieldDate,
    'date-time-field':    AppFieldDateTime,
    'default-field':      AppFieldDefault,
    'hidden-field':       AppFieldHidden,
    'radio-field':        AppFieldRadio,
    'range-field':        AppFieldRange,
    'richtext-field':     AppFieldRichText,
    'select-field':       AppFieldSelect,
    'text-field':         AppFieldText,
    'textarea-field':     AppFieldTextarea,
}