import { api as Api, token as Token }                         from "@greywind/common";
import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router';
import store                                                  from "@/store";
import Home                                                   from '@/views/Home.vue';

const routes: Array<RouteRecordRaw> = [
    {
        path:      '/',
        name:      'Home',
        component: Home
    },
    {
        path:      '/categories',
        name:      'Categories',
        component: () => import(/* webpackChunkName: "categories" */ '../views/categories/index.vue')
    },
    {
        path:      '/categories/create',
        name:      'NewCategory',
        component: () => import(/* webpackChunkName: "categories.create" */ '../views/categories/Create.vue')
    },
    {
        path:      '/categories/edit/:id',
        name:      'EditCategory',
        component: () => import(/* webpackChunkName: "categories.edit" */ '../views/categories/Edit.vue')
    },
    {
        path:      '/rate-types',
        name:      'RateTypes',
        component: () => import(/* webpackChunkName: "rate-types" */ '../views/rate-types/index.vue')
    },
    {
        path:      '/rate-types/create',
        name:      'NewRateType',
        component: () => import(/* webpackChunkName: "rate-types.create" */ '../views/rate-types/Create.vue')
    },
    {
        path:      '/rate-types/edit/:id',
        name:      'EditRateType',
        component: () => import(/* webpackChunkName: "rate-types.edit" */ '../views/rate-types/Edit.vue')
    },
    {
        path:      '/terms',
        name:      'Terms',
        component: () => import(/* webpackChunkName: "terms" */ '../views/terms/index.vue')
    },
    {
        path:      '/terms/create',
        name:      'NewTerms',
        component: () => import(/* webpackChunkName: "terms.create" */ '../views/terms/Create.vue')
    },
    {
        path:      '/terms/edit/:id',
        name:      'EditTerm',
        component: () => import(/* webpackChunkName: "terms.edit" */ '../views/terms/Edit.vue')
    },
    {
        path:      '/401',
        name:      '401',
        component: () => import(/* webpackChunkName: "401" */ '../views/errors/401.vue')
    },
    {
        path:      '/ranges/:brand',
        name:      'Ranges',
        component: () => import(/* webpackChunkName: "ranges" */ '../views/ranges/index.vue')
    },
    {
        path:      '/ranges/:brand/new',
        name:      'NewRange',
        component: () => import(/* webpackChunkName: "ranges.create" */ '../views/ranges/Create.vue')
    },
    {
        path:      '/ranges/:brand/edit/:id',
        name:      'EditRange',
        component: () => import(/* webpackChunkName: "ranges.edit" */ '../views/ranges/Edit.vue')
    },
    {
        path:      '/ranges/:brand/models/:range',
        name:      'Models',
        component: () => import(/* webpackChunkName: "models" */ '../views/models/index.vue')
    },
    {
        path:      '/ranges/:brand/models/:range/new',
        name:      'NewModel',
        component: () => import(/* webpackChunkName: "models.create" */ '../views/models/Create.vue')
    },
    {
        path:      '/ranges/:brand/models/:range/edit/:id',
        name:      'EditModel',
        component: () => import(/* webpackChunkName: "models.edit" */ '../views/models/Edit.vue')
    },
    {
        path:      '/ranges/:brand/models/:range/derivatives/:model',
        name:      'Derivatives',
        component: () => import(/* webpackChunkName: "derivatives" */ '../views/derivatives/index.vue')
    },
    {
        path:      '/ranges/:brand/models/:range/derivatives/:model/new',
        name:      'NewDerivative',
        component: () => import(/* webpackChunkName: "derivatives.create" */ '../views/derivatives/Create.vue')
    },
    {
        path:      '/ranges/:brand/models/:range/derivatives/:model/edit/:id',
        name:      'EditDerivative',
        component: () => import(/* webpackChunkName: "derivatives.edit" */ '../views/derivatives/Edit.vue')
    },
    {
        path:      '/ranges/:brand/models/:range/derivatives/:model/rates/:derivative',
        name:      'Rates',
        component: () => import(/* webpackChunkName: "rates" */ '../views/Rates.vue')
    }
]

const router = createRouter(
    {
        history: createWebHashHistory(),
        routes
    }
);


router.beforeEach( async ( to, from ) => {
    await Promise.all(
        [
            store.dispatch( "title/setContents", "" ),
            store.dispatch( "title/setBack", {} ),
            store.dispatch( "title/setAction", {} )
        ]
    );

    if ( window.Denfield ) {
        if ( from.name ) {
            window.Denfield.send( 'ECHO_LEAVE', `bespoke.offers.${ from.name }` );
        }

        window.Denfield.send( 'ECHO_JOIN', `bespoke.offers.${ to.name }` );
    }

    if ( to.path === '/401' ) return;

    if ( process.env.NODE_ENV === "development" && process.env.VUE_APP_BEARER ) {
        Token.store( process.env.VUE_APP_BEARER, 'token' );
    } else if ( !Token.get() && navigator.userAgent.startsWith( 'X-DENFIELD-APP' ) ) {
        const parts = navigator.userAgent.split( '/' );
        const token = parts.length ? parts[ 1 ] : false;
        if ( token ) {
            Token.store( token, 'token' );
        } else {
            return '/401';
        }
    }

    try {
        await store.dispatch( "auth/verify" );
    } catch ( e ) {
        return '/401';
    }
} );

export default router
