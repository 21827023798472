import { createStore } from 'vuex'
import auth            from './auth';
import title           from './title';
import theme           from '@ui/store/theme.ts';
import media           from "@ui/store/media.ts";

export default createStore(
    {
        modules: {
            auth,
            theme,
            title,
            media
        }
    }
)
