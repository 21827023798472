import useColors from "../composables/colors";

const tailwindConfig = require( '@/../tailwind.config' );

declare const window: Window & typeof globalThis & {
    Store: any
}

const persistentStore = window.Store || localStorage;

const generate = ( color ) => {
    if ( !isColorValid( color ) ) {
        return;
    }

    return useColors( color ).colors;
}

const isColorValid = ( color ) => {
    if ( !color ) {
        return false;
    }
    return /^#?[0-9A-F]{6}$/i.test( color );
}

const state = {
    default: tailwindConfig.theme.extend.colors.blue.DEFAULT,
    current: tailwindConfig.theme.extend.colors.blue.DEFAULT,
    colors:  {}
}

const getters = {
    default( state ) {
        return state.default;
    },
    current( state ) {
        return state.current;
    },
    colors( state ) {
        return state.colors;
    }
}

const actions = {
    clear( { commit, state } ) {
        commit( 'set', state.default );
        commit( 'setColors', generate( state.default ) );
    },
    preloadTheme( { commit, state } ) {
        commit( 'set', ( persistentStore instanceof Storage ? persistentStore.getItem( 'theme' ) : persistentStore.get( 'theme' ) ) || state.current );
        commit( 'setColors', generate( ( persistentStore instanceof Storage ? persistentStore.getItem( 'theme' ) : persistentStore.get( 'theme' ) ) || state.current ) );
    },
    setTheme( { commit, state }, user ) {
        let theme    = state.current;
        const client = user.clients.filter( client => user.active_client_id === client.id )[ 0 ];

        if ( user.theme ) {
            theme = user.theme;
        } else if ( client && client.meta && client.meta.theme ) {
            theme = client.meta.theme;
        }

        if ( theme !== state.current ) {
            if ( persistentStore instanceof Storage ) {
                persistentStore.setItem( 'theme', theme );
            } else {
                persistentStore.set( 'theme', theme );
            }

            commit( 'set', theme );
            commit( 'setColors', generate( theme ) );
        }
    }
}

const mutations = {
    set( state, payload ) {
        state.current = '#' + payload.replace( '#', '' );
    },
    setColors( state, payload ) {
        state.colors = payload;
    }
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}
