import { MediaModel as Media } from "@greywind/common";

let model;
const state = {
    loading:           true,
    fieldName:         '',
    mode:              'library',
    path:              '',
    searchTerm:        '',
    searchAll:         false,
    files:             [],
    folders:           {},
    allFilesSelected:  false,
    previewsEnabled:   true,
    selectedFiles:     [],
    searchResults:     [],
    allowedExtensions: [],
    file:              null,
    modals:            {
        upload:       {
            open: false
        },
        preview:      {
            open: false,
            file: null
        },
        folder:       {
            open: false
        },
        deleteFile:   {
            open:  false,
            value: null
        },
        deleteFolder: {
            open:  false,
            value: null
        }
    }
}

const getters = {
    loading( state ) {
        return state.loading;
    },
    path( state ) {
        return state.path;
    },
    fieldName( state ) {
        return state.fieldName;
    },
    file( state ) {
        return state.file;
    },
    files( state ) {
        return state.files;
    },
    folders( state ) {
        return state.folders;
    },
    mode( state ) {
        return state.mode;
    },
    modals( state ) {
        return state.modals;
    },
    allFilesSelected( state ) {
        return state.allFilesSelected;
    },
    selectedFiles( state ) {
        return state.selectedFiles;
    },
    searchResults( state ) {
        return state.searchResults;
    },
    allFiles( state ) {
        return Object.keys( state.folders ).map( f => ( {
            type: 'folder',
            path: f,
            name: state.folders[ f ]
        } ) ).concat( state.files.map( f => ( {
            ...f,
            type: 'file'
        } ) ).filter( file => !state.allowedExtensions.length || state.allowedExtensions.indexOf( file.file_name.split( '.' ).pop().toUpperCase() ) !== -1 ) );
    },
    backPath( state ) {
        if ( !state.path ) return null;

        const newPath = state.path.split( '/' );
        newPath.pop();

        return newPath.length > 0 ? newPath.join( '/' ) : null;
    }
}

const actions = {
    initiate( context ) {
        model = new Media( 'denfield-app-user/denfield-package-media', context.rootGetters[ 'auth/activeEnvironment' ].meta.api.url );

        return Promise.all(
            [
                context.dispatch( 'getFolders' ),
                context.dispatch( 'searchFiles', { filters: [] })
            ]
        );
    },
    getFolders( context ) {
        return model.folders( { path: context.state.path } )
                    .then( ( { data: { data } } ) => context.dispatch( 'setFolders', ( !Array.isArray( data ) ? data : {} ) ) );
    },
    getFiles( context ) {
        return model.all( { path: context.state.path } )
                    .then( ( { data: { data } } ) => context.dispatch( 'setFiles', data ) );
    },
    searchFiles( context, { filters=[] } ) {
        return model.search( { path: context.state.path, search: context.state.searchTerm, filters: filters, all: context.state.searchAll } )
                    .then( ( { data: { data } } ) => context.dispatch( 'setFiles', data ) );
    },
    clearFolders( context ) {
        context.dispatch( 'setFolders', ( {} ) );
    },
    clearFiles( context ) {
        context.dispatch( 'setFiles', ( [] ) );
    },
    setPreviewsEnabled( { commit }, payload ) {
        return commit( 'updatedPreviewsEnabled', payload );
    },
    setFieldName( { commit }, payload ) {
        return commit( 'updateFieldName', payload );
    },
    setPath( { commit }, payload ) {
        return commit( 'updatePath', payload );
    },
    setSearchTerm( { commit }, payload ) {
        return commit( 'updateSearchTerm', payload );
    },
    setSearchAll( { commit }, payload ) {
        return commit( 'updateSearchAll', payload );
    },
    setLoading( { commit }, payload ) {
        return commit( 'updateLoading', payload );
    },
    setFiles( { commit }, payload ) {
        return commit( 'updateFiles', payload );
    },
    setFile( { commit }, payload ) {
        return commit( 'updateFile', payload );
    },
    setSearchResults( { commit }, payload ) {
        return commit( 'updateSearchResults', payload );
    },
    setFolders( { commit }, payload ) {
        return commit( 'updateFolders', payload );
    },
    setAllowedExtensions( { commit }, payload ) {
        return commit( 'updateAllowedExtensions', payload );
    },
    setModals( { commit }, payload ) {
        return commit( 'updateModals', payload );
    },
    setModal( { commit }, payload ) {
        return commit( 'updateModal', payload );
    },
    clearModals( { commit } ) {
        return commit( 'clearModals' );
    },
    setMode( { commit }, payload ) {
        return commit( 'updateMode', payload );
    },
    setAllFilesSelected( { commit }, payload ) {
        return commit( 'updateAllFilesSelected', payload );
    },
    setSelectedFiles( { commit }, payload ) {
        return commit( 'updateSelectedFiles', payload );
    },
    toggleModalOpen( { commit }, payload ) {
        return commit( 'updateModalOpen', payload );
    },
    setModalFile( { commit }, payload ) {
        return commit( 'updateModalFile', payload );
    },
    resetState( { commit } ) {
        return commit( 'resetState' );
    },
}

const mutations = {
    resetState( state ) {
        const original = getDefaultState();

        state.loading           = original.loading;
        state.path              = original.path;
        state.searchTerm        = original.searchTerm;
        state.searchAll         = original.searchAll;
        state.mode              = original.mode;
        state.files             = original.files;
        state.folders           = original.folders;
        state.allFilesSelected  = original.allFilesSelected;
        state.selectedFiles     = original.selectedFiles;
        state.searchResults     = original.searchResults;
        state.allowedExtensions = original.allowedExtensions;
        state.modals            = original.modals;
        state.file              = original.file;
    },
    updateFieldName( state, payload ) {
        state.fieldName = payload;
    },
    updatePath( state, payload ) {
        state.path = payload;
    },
    updateSearchTerm( state, payload ) {
        state.searchTerm = payload;
    },
    updateSearchAll( state, payload ) {
        state.searchAll = payload;
    },
    updateLoading( state, payload ) {
        state.loading = payload;
    },
    updateAllowedExtensions( state, payload ) {
        state.allowedExtensions = payload.map( type => type.toUpperCase() );
    },
    updateFiles( state, payload ) {
        state.files = payload;
    },
    updateFile( state, payload ) {
        state.file = payload;
    },
    updateMode( state, payload ) {
        state.mode = payload;
    },
    updateSearchResults( state, payload ) {
        state.searchResults = payload
    },
    updateFolders( state, payload ) {
        state.folders = payload;
    },
    updatedPreviewsEnabled( state, payload ) {
        state.previewsEnabled = payload;
    },
    updateAllFilesSelected( state, payload ) {
        state.allFilesSelected = payload;
    },
    updateSelectedFiles( state, payload ) {
        state.selectedFiles = payload;
    },
    updateModals( state, payload ) {
        state.modals = payload;
    },
    updateModal( state, payload ) {
        state.modals[ payload.key ] = payload.value;
    },
    updateModalOpen( state, payload ) {
        state.modals[ payload ].open = !state.modals[ payload ].open;
    },
    updateModalFile( state, payload ) {
        state.modals[ payload.key ].file = payload.value;
    },
    clearModals( state ) {
        state.modals = getDefaultState().modals;
    }
}

const getDefaultState = () => ( {
    loading:           true,
    fieldName:         '',
    mode:              'library',
    path:              '',
    searchTerm:        '',
    searchAll:         false,
    files:             [],
    folders:           {},
    allFilesSelected:  false,
    previewsEnabled:   true,
    selectedFiles:     [],
    searchResults:     [],
    allowedExtensions: [],
    file:              null,
    modals:            {
        upload:       {
            open: false
        },
        preview:      {
            open: false,
            file: null
        },
        folder:       {
            open: false
        },
        deleteFile:   {
            open:  false,
            value: null
        },
        deleteFolder: {
            open:  false,
            value: null
        }
    }
} );


export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}
