import { computed } from "vue";

export function loadFieldSettings( store ): any {
    const theme = computed( () => store.getters[ "builder/theme" ] );

    const getInputTypeValue = ( type: string, value: any, defaultValue: any ): any => {
        switch ( type ) {
            case "checkbox":
                return value === false || value === true ? value : defaultValue;
            default:
                return value !== undefined ? value : defaultValue;
        }
    }

    const getInputType = ( type: string ): string => {
        switch ( type ) {
            case "checkbox":
                return 'boolean-field';
            case "code":
                return "html-field";
            case "color":
                return 'colour-field';
            case "colour":
                return 'colour-field';
            case "textarea":
                return 'textarea-field';
            case "radio":
                return "radio-field";
            case "range":
                return "range-field";
            case "date":
                return "date-field";
            case "datetime":
                return "date-time-field";
            case "richtext":
                return "richtext-field";
            case "select":
                return "select-field";
            case "multiselect":
                return "multiselect-field";
            case "repeatable":
                return "repeatable-field";
            case "html":
                return "html-field";
            case "formSelect":
                return "form-select-field";
            case "media":
                return "media-field";
            case "link":
                return "link-field";
            case "menu":
                return "menu-field";
            case "gallery":
                return "gallery-field";
            case "page":
                return 'page-field';
            case "parent-value":
                return "parent-value-field";
            case "options":
                return "options-field";

            default:
                return 'text-field';
        }
    }

    const getInputTypeExtras = ( setting: any ): any => {
        let settings = {};
        if ( setting.type === 'color' || setting.type === 'colour' ) {
            if ( theme.value && theme.value.config && theme.value.config.presets ) {
                settings.presets = theme.value.config.presets;
            }
        } else if ( [ 'radio', 'select', 'multiselect' ].indexOf( setting.type ) !== -1 ) {
            settings.options    = setting.options || ( setting.meta && setting.meta.options ? setting.meta.options : [] );
            settings.searchable = setting.searchable || !!( setting.meta && setting.meta.searchable ) || false;
        } else if ( [ 'range', 'date', 'datetime', 'richtext', 'html', 'code' ].indexOf( setting.type ) !== -1 ) {
            settings.options = setting.options || ( setting.meta && setting.meta.options ? setting.meta.options : {} );
        } else if ( setting.type === "number" ) {
            settings.type = "number";
        } else if ( setting.type === "repeatable" ) {
            settings.fields   = Array.isArray( setting.fields ) ? setting.fields : [ setting.fields ];
            settings.singular = setting.singular;
            settings.limit    = setting.limit || null;

            settings = { ...settings, ...getInputTypeExtras( { ...setting, type: setting.field } ) }
        }

        return settings;
    }

    return { getInputType, getInputTypeExtras, getInputTypeValue };
}