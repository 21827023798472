const useConfig    = require( "../../build/tailwind.config.js" );
const defaultTheme = require( "tailwindcss/defaultTheme" );

const config = useConfig( defaultTheme );

module.exports = {
  ...config,
  plugins:  [
    require( '@tailwindcss/forms' )
  ],
}
