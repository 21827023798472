import { computed } from 'vue';

export function userHook( store: any ): any {
    const user         = computed( () => store.getters[ "auth/currentUser" ] );
    const activeClient = computed( () => store.getters[ "auth/activeClient" ] );
    const activeEnvironment = computed( () => store.getters[ "auth/activeEnvironment" ] );

    return {
        user,
        activeClient,
        activeEnvironment,
    };
}