import { createApp } from 'vue'
import App           from './App.vue'
import router        from './router'
import store         from './store'
import Popper        from "vue3-popper";

import Alpine from 'alpinejs';

window.Alpine = Alpine;
Alpine.start();

// Load styles
import './index.css';
import '../../../build/popper.css';
import '@greywind/ui/build/style.css';
import '@greywind/common/build/style.css';

const app = createApp( App );

import { components } from "@greywind/common";
import appComponents  from "@/bags/components";

Object.keys( { ...appComponents, ...components } ).forEach( ( key: string ) => {
    app.component( key, ( appComponents as any )[ key ] || ( components as any )[ key ] );
} );

if ( window.Denfield ) {
    window.Denfield.send( 'ECHO_INIT', [
        { event: '.Denfield\\Offers\\Events\\CategoryCreated', handle: 'CategoryCreated' },
        { event: '.Denfield\\Offers\\Events\\CategoryUpdated', handle: 'CategoryUpdated' },
    ] );
}

app.use( store ).use( router ).mount( '#app' )
