import { ref } from "vue";

export function useConfig() {
    const config = ref(
        {
            // General
            model:        null,
            shouldReload: false,
            files:        [],

            // Files
            view:      'grid',
            searching: false,
            error: null,

            // Folder
            name:    '',
            loading: false,

            // Preview
            src:         null,
            numPages:    0,
            url:         '',
            updatedFile: null,
        }
    );

    return { config };
}