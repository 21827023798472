module.exports = function useConfig( defaultTheme ) {
    return {
        purge:    false,
        darkMode: 'media',
        theme:    {
            extend: {
                fontFamily:   {
                    sans: [ "Roboto", ...defaultTheme.fontFamily.sans ]
                },
                fontSize:     {
                    '6xl': [ '64px', {
                        lineHeight: '90px'
                    } ],
                },
                lineHeight:   {
                    'normal': '1.875rem'
                },
                maxWidth:     {
                    'xxs':  '15rem',
                    'xxxs': '10rem'
                },
                width: {
                    'w-112': '28rem'
                },
                boxShadow:    {
                    sm:      '0px 2px 5px #26334D08',
                    DEFAULT: '0px 2px 10px #26334D0D',
                    md:      '0px 2px 15px #26334D0D',
                    lg:      '0px 10px 30px #26334D0D',
                    xl:      '0px 30px 60px #26334D0D',
                    '2xl':   '0px 60px 120px #26334D0D',
                    '3xl':   '0px 90px 240px #26334D0D'
                },
                borderRadius: {
                    'none':  '0',
                    'sm':    '0.3125rem',
                    DEFAULT: '0.625rem',
                    DEFAULT: '10px',
                    'md':    '0.9375rem',
                    'lg':    '1.25rem',
                    'xl':    '1.5625rem',
                    '2xl':   '1.875rem',
                    'full':  '9999px',
                    'large': '12px',
                },
                colors:       {
                    'denfield-dark': '#2c2e4f',
                    blue:            {
                        DEFAULT: '#3361FF',
                        '50':    '#FFFFFF',
                        '100':   '#eff2f9',
                        '200':   '#CCD8FF',
                        '300':   '#99B0FF',
                        '400':   '#6688FF',
                        '500':   '#3361FF',
                        '600':   '#0039FF',
                        '700':   '#002ECC',
                        '800':   '#002299',
                        '900':   '#001766'
                    },
                    green:           {
                        DEFAULT: '#29CC39',
                        '50':    '#E1F9E4',
                        '100':   '#CCF5D0',
                        '200':   '#A2ECA9',
                        '300':   '#77E482',
                        '400':   '#4DDB5B',
                        '500':   '#29CC39',
                        '600':   '#13BF24',
                        '700':   '#20A22D',
                        '800':   '#187721',
                        '900':   '#07220A'
                    },
                    pink:            {
                        DEFAULT: '#E62E7B',
                        '50':    '#FFFBFC',
                        '100':   '#FCE4EE',
                        '200':   '#F6B7D1',
                        '300':   '#F189B5',
                        '400':   '#EB5C98',
                        '500':   '#E62E7B',
                        '600':   '#C91862',
                        '700':   '#9B134C',
                        '800':   '#6E0D36',
                        '900':   '#40081F'
                    },
                    purple:          {
                        DEFAULT: '#8833FF',
                        '50':    '#FFFFFF',
                        '100':   '#FFFFFF',
                        '200':   '#E1CCFF',
                        '300':   '#C399FF',
                        '400':   '#A666FF',
                        '500':   '#8833FF',
                        '600':   '#6A00FF',
                        '700':   '#5500CC',
                        '800':   '#400099',
                        '900':   '#2A0066'
                    },
                    orange:          {
                        DEFAULT: '#FF6633',
                        '50':    '#FFFFFF',
                        '100':   '#FFFFFF',
                        '200':   '#FFD9CC',
                        '300':   '#FFB399',
                        '400':   '#FF8C66',
                        '500':   '#FF6633',
                        '600':   '#FF4000',
                        '700':   '#CC3300',
                        '800':   '#992600',
                        '900':   '#661A00'
                    },
                    teal:            {
                        DEFAULT: '#33BFFF',
                        '50':    '#FFFFFF',
                        '100':   '#FFFFFF',
                        '200':   '#CCEFFF',
                        '300':   '#99DFFF',
                        '400':   '#66CFFF',
                        '500':   '#33BFFF',
                        '600':   '#00AFFF',
                        '700':   '#008CCC',
                        '800':   '#006999',
                        '900':   '#004666'
                    },
                    'gray-blue':     {
                        DEFAULT: '#E4E7F0',
                        '50':    '#FAFBFC',
                        '100':   '#F7F8FA',
                        '200':   '#F5F6F7',
                        '300':   '#F2F3F5',
                        '400':   '#EDEFF2',
                        '500':   '#E4E7F0',
                        '600':   '#E1E5ED',
                        '700':   '#DFE3EB',
                        '800':   '#DCE0E8',
                        '900':   '#DADEE6'
                    },
                    'gray-bluer':    {
                        DEFAULT: '#3F5073',
                        '50':    '#C3CAD9',
                        '100':   '#99A6BF',
                        '200':   '#7D8FB3',
                        '300':   '#6B7A99',
                        '400':   '#4D5E80',
                        '500':   '#3F5073',
                        '600':   '#2D3C59',
                        '700':   '#26334D',
                        '800':   '#1A2233',
                        '900':   '#0D111A'
                    },
                    red:             {
                        DEFAULT: '#E62E2E',
                        '50':    '#FFFBFB',
                        '100':   '#FCE4E4',
                        '200':   '#F6B7B7',
                        '300':   '#F18989',
                        '400':   '#EB5C5C',
                        '500':   '#E62E2E',
                        '600':   '#C91818',
                        '700':   '#9B1313',
                        '800':   '#6E0D0D',
                        '900':   '#400808'
                    },
                    yellow:          {
                        DEFAULT: '#FFCB33',
                        '50':    '#FFFFFF',
                        '100':   '#FFFFFF',
                        '200':   '#FFF2CC',
                        '300':   '#FFE599',
                        '400':   '#FFD866',
                        '500':   '#FFCB33',
                        '600':   '#FFBE00',
                        '700':   '#CC9800',
                        '800':   '#997200',
                        '900':   '#664C00'
                    },
                    teal:            {
                        DEFAULT: '#2EE6CA',
                        '50':    '#FBFFFE',
                        '100':   '#E4FCF8',
                        '200':   '#B7F6ED',
                        '300':   '#89F1E1',
                        '400':   '#5CEBD6',
                        '500':   '#2EE6CA',
                        '600':   '#18C9AE',
                        '700':   '#139B87',
                        '800':   '#0D6E5F',
                        '900':   '#084038'
                    },
                    theme:           {
                        DEFAULT: 'var(--color-theme-500)',
                        '50':    'var(--color-theme-50)',
                        '100':   'var(--color-theme-100)',
                        '200':   'var(--color-theme-200)',
                        '300':   'var(--color-theme-300)',
                        '400':   'var(--color-theme-400)',
                        '500':   'var(--color-theme-500)',
                        '600':   'var(--color-theme-600)',
                        '700':   'var(--color-theme-700)',
                        '800':   'var(--color-theme-800)',
                        '900':   'var(--color-theme-900)'
                    }
                }
            },
        },
        variants: {
            extend: {
                ringWidth: [ 'hover', 'active' ],
                ringColor: [ 'hover', 'active' ]
            },
        }
    }
}
